.copyright .nav-link {
  display: contents;
}

.card-header-sp img {
  max-width: 260px;
}

.enroll-security-key-page {
  .card-header-sp img {
    max-width: 300px;
  }
}